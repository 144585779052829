import { FormProvider, useForm } from "react-hook-form";

import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { FormCheckBox } from "../../../../../components/ui/CheckBoxes/FormCheckBox";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { ApiService } from "../../../../../services/ApiService";
import { isSSR } from "../../../../../utils/helpers";
import {
  errorToast,
  successToast
} from "../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../components/ui/ButtonLoader/ButtonLoader";

export const TelegramSettings = ({ controller }) => {
  const { lang } = useParams();
  // Dispatch

  const methods = useForm();
  const { setValue } = methods;

  // **State
  const {
    cabinet: { telegram },
    notifications
  } = useTranslation();

  const { telegramBot } = useSelector((state) => state.content.proxies.data);

  const [telegramInfo, setTelegramInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getTelegramInfo = () => {
    if (telegramBot) {
      ApiService.getTelegramInfo(controller).then((req) => {
        if (req.status === 200 && req?.data) {
          const { notificationEnabled } = req.data;
          methods.reset({ notificationEnabled });
          setTelegramInfo(req.data);
        }
      });
    }
  };

  const handleGoBot = (e) => {
    e.preventDefault();
    if (!isSSR() && telegramInfo?.linkToAuth) {
      window.open(telegramInfo?.linkToAuth, "_blank");
    }
  };

  useEffect(() => {
    getTelegramInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form submit
  const onSubmit = (data) => {
    setIsLoading(true);

    ApiService.setTelegramInfo(data?.notificationEnabled)
      .then((req) => {
      if (req.status === 200 && req?.data) {
        setTelegramInfo(req.data);
        successToast(notifications?.distributionChange, lang);
      } else errorToast(notifications?.error, lang);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckBoxChange = (name, value) => {
    setValue(name, value);
  };

  return telegramInfo?.linkToAuth ? (
    <div className="notifications__box">
      <div className="notifications__title">{telegram?.title}</div>
      <div className="notifications__descriptions ">
        <IconSvg tag={"info"} className="info-icon" />
        <div className="info-box">
          {telegram?.descriptions}
          <Link className="info-link" to={"#"} onClick={handleGoBot}>
            {telegram?.botLink}
          </Link>
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="notifications__form"
        >
          <ul className="notifications__list">
            <li className="notifications__list-item">
              <FormCheckBox
                name="notificationEnabled"
                defaultValue={telegramInfo?.notificationEnabled}
                label={telegram?.turnOn}
                handleClick={(e) => handleCheckBoxChange("notificationEnabled", e.target.checked)}
              />
            </li>
          </ul>
          <Button
            type="submit"
            tab="confirm"
            initClass="notifications__button"
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader /> : telegram?.formBtn }
          </Button>
        </form>
      </FormProvider>
    </div>
  ) : null;
};
