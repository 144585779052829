import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import { useParams, useSearchParams } from "react-router-dom";
import MediaQuery from "react-responsive";
import { CheckBox } from "../../../../../components/ui/CheckBoxes/CheckBox";
import { NoDataComponent } from "../../../../../components/common/NoDataComponent/NoDataComponent";
import { Pagination } from "../../../../../components/common/Pagination/Pagination";
import { ApiService } from "../../../../../services/ApiService";
import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { rowPerPageOptions } from "../../../../../utils/constants/rowPerPage.constant";
import { errorToast } from "../../../../../utils/helpers/customToast.helper";
import { Loading } from "../../../../../components/ui/Loading/Loading";
import { MoveToFolderSelect } from "../MoveToFolderSelect/MoveToFolderSelect";
import { CustomModal } from "../../../../../components/ui/CustomModal/CustomModal";
import { clearObjectEmptyValues } from "../../../../../utils/helpers/clearObjectEmptyValues";
import { moveTo } from "../../../../../utils/helpers/moveTo";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { getBorderColor } from "../../../../../utils/helpers/getBorderColor";
import { FormatCellCheckbox, FormatCellIP, FormatCellLinkMobileReboot } from "./components/TableColumns";
import { FormatCellAutoRenewal } from "./components/TableColumns/FormatCellAutoRenewal";
import { FormatCellNotesData } from "./components/TableColumns/FormatCellNotesData";
import { FormatCellProtocol } from "./components/TableColumns/FormatCellProtocol";
import { FormatCellIpStatus } from "./components/TableColumns/FormatCellIpStatus";
import { UpdateTableDataModal } from "./components/TableModals/UpdateTableDataModal";
import { FormatCellFilter } from "./components/TableColumns/FormatCellFilter";
import { FormatCellNotesHead } from "./components/TableColumns/FormatCellNotesHead";
import { FormatCellAutoRenewalHead } from "./components/TableColumns/FormatCellAutoRenewalHead";
import { TableRowLoader } from "./components/ui/TableRowLoader/TableRowLoader";
import { FormatCellDnd } from "./components/TableColumns/FormatCellDnd";
import { DesktopNoData } from "./components/ui/DesktopNoData/DesktopNoData";
import { EditNotesModal } from "./components/TableModals/EditNotesModal/EditNotesModal";
import "./Table.scss";

export const Table = ({ closeFolderSelect, controller, folders, setFolders }) => {
  const { lang } = useParams();
  // Dispatch
  const {
    setSelectedRows,
    fetchTableData,
    setTableRowLoading
  } = useDispatchedActions();

  // **Redux State
  const { orders } = useTranslation().cabinet;
  const { select: { noOptionsMessage }, notifications, cabinet: { balance } } = useTranslation();
  const {
    tableData,
    isTableDataLoaded,
    fetchTableDataError,
    selectedRows,
    isTableDataCount,
    selectedFolder,
    isTableRowLoading
  } = useSelector((state) => state.cabinet);
  const { activeData } = useSelector((state) => state.content);

  // **Local state
  const [localSize, setLocalSize] = useState(10);
  const [localCurrentPage, setLocalCurrentPage] = useState(1);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const dateStart = searchParams.get("dateStart") || null;
  const dateEnd = searchParams.get("dateEnd") || null;

  const { skin } = useSelector((state) => state.header);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.select.setValue({ label: `${localSize} ${balance?.lines}`, value: localSize });
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const filterParams = {
    country: searchParams.get("country"),
    runningOut: JSON.parse(searchParams.get("runningOuts")),
    description: searchParams.get("description")

  };

  const filterByDateParams = {
    filterOffDate: !!dateStart || !!dateEnd,
    filterOffDateStart: dateStart,
    filterOffDateEnd: dateEnd
  };

  const sort = {
    [searchParams.get("sort")]: searchParams.get("order")
  };

  const isPaginationVisible = tableData?.totalElements > 10;
  const TOP_OF_TABLE = 420;

  // Page change handler
  const handlePageChange = (page) => {
    setLocalCurrentPage(page);
    moveTo(0, TOP_OF_TABLE);
    fetchTableData({
      page: page - 1,
      size: localSize,
      proxyType: activeData?.proxy?.code,
      selectedFolder,
      filterParams,
      sort,
      filterByDateParams
    });
  };

  // Rows size handler
  const handlePerRowsChange = (newPerPage) => {
    setLocalSize(newPerPage?.value);
    setLocalCurrentPage(1);
    fetchTableData({
      page: 0,
      size: Number(newPerPage?.value),
      proxyType: activeData?.proxy?.code,
      selectedFolder,
      filterParams,
      sort,
      filterByDateParams
    });
  };

  // Define rows per page options
  const defineRowsPerPageOptions = () =>
    [
      ...rowPerPageOptions.filter((item) => item < tableData?.totalElements),
      tableData?.totalElements
    ].map((item) => ({ label: `${item} ${balance?.lines}`, value: item }));

  // select all checkbox
  const selectAllCheckbox = async ({ target: { checked } }) => {
    if (tableData?.content.length < 1) {
      return;
    }

    if (checked) {
      setTableRowLoading(true);
      try {
        clearObjectEmptyValues(filterParams);

        const { data, status } = await ApiService
          .getAllUserOrdersByFolder(activeData?.proxy?.code, selectedFolder.value, filterParams, filterByDateParams);

        if (status !== 200) {
          throw Error();
        }

        setSelectedRows(data);
      } catch (e) {
        errorToast("400 Bad request", lang);
      } finally {
        setTableRowLoading(false);
      }

      return;
    }

    setSelectedRows([]);
  };

  // first fetching table data
  useEffect(() => {
    if (activeData?.proxy?.code) {
      setLocalSize(10);
      setLocalCurrentPage(1);
      setSelectedRows([]);
      fetchTableData({
        page: 0,
        size: 10,
        proxyType: activeData?.proxy?.code,
        selectedFolder,
        filterParams,
        sort,
        controller,
        filterByDateParams
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy?.code, selectedFolder, searchParams]);

  // Handling error
  if (fetchTableDataError) {
    errorToast(notifications?.error, lang);
  }

  const rowIsChecked = (row) => selectedRows.some((id) => id === row.id);

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  return (
    <div className="table__container">
      <div className={tableData?.totalElements ? "table-contain" : ""}>
        <div className="table-box">
          <table className="table">
            <thead className="table-head">
            <tr className="table-head-tr">
              <th className="dnd-container">
                <div className="dnd-head"></div>
              </th>
              <th className="table-head-tr-item id">
                <CheckBox
                  checked={
                    isTableDataCount > 0 && isTableDataCount === selectedRows.length
                  }
                  onChange={selectAllCheckbox}
                  partly={
                    selectedRows.length > 0 && selectedRows.length !== isTableDataCount
                  }
                  disabled={isTableRowLoading}
                />
              </th>
              <th className="table-head-tr-item filter-table">
                <FormatCellFilter />
              </th>
              <th className="table-head-tr-item ip">
                {orders?.column?.ip?.name}
              </th>
              {activeData?.proxy?.code === "MOBILE" && (
                <th className="table-head-tr-item country">
                  {orders?.column?.rotation}
                </th>
              )}
              {/*<th className="table-head-tr-item country">*/}
              {/*  {orders?.column?.country}*/}
              {/*</th>*/}
              <th className="table-head-tr-item https">
                {orders?.column?.ip?.http}
              </th>
              <th className="table-head-tr-item socks">
                {orders?.column?.ip?.socks}
              </th>
              <th className="table-head-tr-item auto-renewal">
                <FormatCellAutoRenewalHead title={orders?.column?.autoRenewal?.title} />
              </th>
              <th className="table-head-tr-item notes">
                <FormatCellNotesHead title={orders?.column?.notes?.title} />
              </th>
              {/*<th className="table-head-tr-item termin">*/}
              {/*  {orders?.column?.term}*/}
              {/*</th>*/}
              {/*<th className="table-head-tr-item status">*/}
              {/*  {orders?.column?.state?.title}*/}
              {/*</th>*/}
            </tr>
            </thead>
            <tbody className="table-body">
            { !isTableDataLoaded ? (
              Array(10).fill("_").map((_, idx) => (
                <TableRowLoader key={idx} />
              ))
            ) : (
              <>
                {tableData?.totalElements !== 0 ? (
                  tableData.content.map((row) => (
                    <Fragment key={row?.id}>
                      <tr
                        className={`table-body-tr ${
                          rowIsChecked(row) ? "table-body-tr-active" : ""
                        }`}
                      >
                        <td className="dnd">
                          <FormatCellDnd row={row} closeFolderSelect={closeFolderSelect} />
                        </td>
                        <td className="table-body-tr-item id">
                          <FormatCellCheckbox row={row} />
                        </td>
                        <td className="table-body-tr-item ip-status">
                          <FormatCellIpStatus order={row} />
                        </td>
                        {/*<td className="table-body-tr-item copy">*/}
                        {/*  <FormatCellCopy row={row} />*/}
                        {/*</td>*/}
                        <td className="table-body-tr-item ip">
                          <FormatCellIP row={row} />
                        </td>
                        {row?.proxyType === "MOBILE" && (
                          <td className="table-body-tr-item country">
                            <FormatCellLinkMobileReboot row={row} />
                          </td>
                        )}
                        {/*<td className="table-body-tr-item country">*/}
                        {/*  <FormatCellCountry countryInfo={row?.countryInfo} />*/}
                        {/*</td>*/}
                        <td className="table-body-tr-item https">
                          <FormatCellProtocol order={row} protocol="HTTP" />
                        </td>
                        <td className="table-body-tr-item socks">
                          <FormatCellProtocol order={row} protocol="SOCKS" />
                        </td>
                        <td className="table-body-tr-item auto-renewal">
                          <FormatCellAutoRenewal
                            order={row}
                          />
                        </td>
                        <td className="table-body-tr-item notes-content">
                          <FormatCellNotesData order={row} setIsEditModalOpen={setIsEditModalOpen} />
                        </td>
                        {/*<td className="table-body-tr-item termin">*/}
                        {/*  <FormatCellDate deadLine={row?.deadLine} />*/}
                        {/*</td>*/}
                        {/*<td className="table-body-tr-item status">*/}
                        {/*  <FormatCellActive isActive={row?.isActive} />*/}
                        {/*</td>*/}
                        {isTableRowLoading && (
                          <Loading tableRow absolute roller={{ transform: "scale(0.5)" }}/>
                        )}
                      </tr>
                    </Fragment>
                  ))
                ) : (
                  <tr className="table-body-tr nodata">
                    <td className="table-body-noData">
                      <MediaQuery minWidth={993}>
                        <DesktopNoData />
                      </MediaQuery>
                      <MediaQuery maxWidth={992}>
                        <NoDataComponent />
                      </MediaQuery>
                    </td>
                  </tr>
                )}
              </>
            )}
            </tbody>
          </table>
        </div>
        <div className="table__pagination">
          <MoveToFolderSelect controller={controller} closeFolderSelect={closeFolderSelect} folders={folders} setFolders={setFolders} />
          {isTableDataLoaded && isPaginationVisible && (
            <Pagination
              totalPages={tableData?.totalPages}
              currentPage={localCurrentPage}
              onPageChange={handlePageChange}
              hideOnSinglePage={false}
            />
          )}
          {isTableDataLoaded && isPaginationVisible && (
            <Select
              className={"modal__select"}
              ref={ref}
              classNamePrefix="modal__select"
              options={defineRowsPerPageOptions()}
              defaultValue={{
                label: `${localSize} ${balance?.lines}`,
                value: localSize
              }}
              menuPlacement="bottom"
              isSearchable={false}
              // placeholder={placeholder}
              onChange={handlePerRowsChange}
              noOptionsMessage={() => noOptionsMessage}
              components={{ DropdownIndicator }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: getBorderColor(state, skin)
                })
              }}
            />
          )}
        </div>
      </div>
      <UpdateTableDataModal />
      <CustomModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        styles={{ zIndex: "9999999" }}
      >
        <EditNotesModal setIsEditModalOpen={setIsEditModalOpen} />
      </CustomModal>
    </div>
  );
};
