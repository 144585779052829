import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToast } from "../../../../../../../../utils/helpers/customToast.helper";
import { CustomSelect } from "../../../../../../../../components/ui/CustomSelect/CustomSelect";
import { protocols, PROXY_TYPES } from "../../../../../../../../utils/constants";
import { CustomModal } from "../../../../../../../../components/ui/CustomModal/CustomModal";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { ApiService } from "../../../../../../../../services/ApiService";

import "./Export.scss";
import { ButtonLoader } from "../../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { useSetZIndex } from "../../../../../../../../hooks/useSetZIndex";
import { GroupButtons } from "../../../../../../../../components/ui/Buttons";
import { Input } from "../../../../../../../../components/ui/Input/Input";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { copyText } from "../../../../../../../../utils/helpers/copyText";

export const FormatModal = ({ open, close }) => {

  useSetZIndex(".cabinet", "999");

  const { lang } = useParams();
  const { activeData } = useSelector((state) => state.content);
  const { selectedRows } = useSelector((state) => state.cabinet);

  const {
    notifications: notificationsMain,
    cabinet: {
      orders,
      notifications,
      export: {
        title,
        file,
        link,
        proxyType,
        template,
        yourFormat,
        format,
        methodsHeading,
        customFormat: customFormatLabel,
        min,
        linkDuration,
        generateLink
      }
    }
  } = useTranslation();

  const expireTimeOptions = [
    { value: 5, label: `5 ${min}` },
    { value: 10, label: `10 ${min}` },
    { value: 15, label: `15 ${min}` },
    { value: 20, label: `20 ${min}` },
    { value: 25, label: `25 ${min}` },
    { value: 30, label: `30 ${min}` }
  ];

  const exportExtensions = [
    { value: "txt", label: "TXT File" },
    { value: "csv", label: "CSV File" },
    { value: "custom", label: customFormatLabel }
  ];

  const ipTemplate = "%ip%";
  const portTemplate = "%port%";
  const protocolTemplate = "%protocol%";
  const authDataTemplate = "%auth_data%";
  const rebootLinkTemplate = "%reboot_link%";

  const methods = [
    ipTemplate,
    portTemplate,
    authDataTemplate,
    protocolTemplate,
    rebootLinkTemplate
  ];

  const inputRef = useRef(null);

  const [selectedProxyType, setSelectedProxyType] = useState(protocols[0]);
  const [selectedExtension, setSelectedExtension] = useState(exportExtensions[0]);
  const [selectedExpireTime, setSelectedExpireTime] = useState(expireTimeOptions[0]);
  const [customFormat, setCustomFormat] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [downloadType, setDownloadType] = useState("file");

  const [exportLink, setExportLink] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await ApiService.getExportLink();

        if (status !== 200) {
          throw new Error();
        }

        if (data?.link) setExportLink(data.link);

      } catch (e) {
        errorToast("Something went wrong!", lang);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeDownloadType = (accessCode) => {
    setDownloadType(accessCode);
  };

  const onClose = () => {
    setSelectedExtension(exportExtensions[0]);
    close();
  };

  const insertMethod = (method) => {
    if (!inputRef.current || selectedExtension.value !== "custom") return;

    const inputElement = inputRef.current;
    const startPos = inputElement.selectionStart;
    const endPos = inputElement.selectionEnd;

    const separator = customFormat && !customFormat.endsWith(":") ? ":" : "";

    const updatedValue = `${customFormat.substring(0, startPos)}${separator}${method}${customFormat.substring(endPos)}`;
    setCustomFormat(updatedValue);

    setTimeout(() => {
      // eslint-disable-next-line no-multi-assign
      inputElement.selectionStart = inputElement.selectionEnd = startPos + method.length + separator.length;
    }, 0);
  };

  const download = (data) => {
    const a = document.createElement("a");
    const fileFormat = selectedExtension.value === "csv" ? "csv" : "txt";

    a.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
    a.setAttribute("download", `ProxyIPv4-list.${fileFormat}`);

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getTemplate = () => {
    const defaultTemplate = `${ipTemplate}:${portTemplate}:${protocolTemplate}:${authDataTemplate}${activeData.proxy.code === PROXY_TYPES.mobile.code ? `:${rebootLinkTemplate}` : ""}`;
    const formats = {
      "txt": defaultTemplate,
      "csv": defaultTemplate.replaceAll(":", ";"),
      "custom": customFormat.replace(rebootLinkTemplate, `${rebootLinkTemplate} `)
    };

    return formats[selectedExtension.value];
  };

  const getDivider = () => selectedExtension.value === "csv" ? ";" : ":";

  const getProxyPort = (proxy) => {
    if (proxy.proxyType === PROXY_TYPES.ipv6.code) return proxy.port;

    return selectedProxyType.value === "HTTP" ? proxy.proxyPort : proxy.socksPort;
  };

  const getExportDataByTemplate = (proxies, template, divider) => {
    const data = proxies.map((proxy) => {
      const ip = proxy.innerIp;
      const port = getProxyPort(proxy);
      const authData = proxy.authType === "login" ? `${proxy.authLogin}${divider}${proxy.authPassword}` : proxy.authIp;
      const rebootLink = proxy.linkReboot || "Reboot link doesn't exist";
      const protocol = selectedProxyType.value;

      return template
        .replace(ipTemplate, ip)
        .replace(portTemplate, port)
        .replace(protocolTemplate, protocol)
        .replace(authDataTemplate, authData)
        .replace(rebootLinkTemplate, rebootLink);
    }).join("\n");

    return data;
  };

  const generateExportLink = async (text) => {
    const params = {
      expireAfterMinutes: selectedExpireTime.value,
      text
    };

    try {
      const { data, status } = await ApiService.createExportLink(params);

      if (status !== 200) {
        throw new Error();
      }

      if (data?.link) setExportLink(data.link);

    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  const exportHandler = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await ApiService.getSelectedClientIps(selectedRows);

      if (status !== 200) {
        throw status;
      }

      const preparedProxies = data.filter((proxy) => {
        if (proxy.proxyType === PROXY_TYPES.ipv6.code) {
          return proxy.protocol === selectedProxyType.value;
        }
        return true;
      });

      const template = getTemplate();
      const divider = getDivider();
      const exportData = getExportDataByTemplate(preparedProxies, template, divider);

      if (downloadType === "file") {
        download(exportData);
        return;
      }

      if (downloadType === "link") {
        generateExportLink(exportData);
      }

    } catch (err) {
      errorToast(notifications?.error, lang);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      styles={{ zIndex: "9999999" }}
      className="export"
      header={
        <>
          <div className="export__header">
            <div className="export__title">{title}</div>
            <GroupButtons
              list={[
                { code: "file", body: file },
                { code: "link", body: link }
              ]}
              active={downloadType}
              handlerClick={changeDownloadType}
            />
          </div>
        </>
      }
    >
      <div className={classNames("export__body", {
        "export__body--hidden": downloadType !== "file"
      })}>
        <CustomSelect
          zIndex="unset"
          label={proxyType}
          value={selectedProxyType}
          options={protocols}
          defaultValue={protocols[0]}
          changeFunc={setSelectedProxyType}
        />
        <CustomSelect
          zIndex="unset"
          label={template}
          options={exportExtensions}
          value={selectedExtension}
          defaultValue={exportExtensions[0]}
          changeFunc={setSelectedExtension}
        />
        <Input
          ref={inputRef}
          label={format}
          placeholder={yourFormat}
          value={customFormat}
          onChange={setCustomFormat}
          disabled={selectedExtension.value !== "custom"}
        />
        <div className="export__hint">
          <div className="export__hint-icon">
            <IconSvg tag="info" />
          </div>
          <div className="export__hint-text">
            {methodsHeading}
          </div>
        </div>
        <ul className="export__methods">
          {methods.map((method) => (
            <li
              key={method}
              className="export__method"
              onClick={() => insertMethod(method)}
            >
              {method}
            </li>
          ))}
        </ul>
        <div className="export__actions">
          <Button
            className="export__cansel"
            onClick={close}
          >
            {orders?.cancel}
          </Button>
          <Button
            tab="confirm"
            onClick={exportHandler}
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader /> : orders?.download }
          </Button>
        </div>
      </div>
      <div className={classNames("export__body export__link", {
        "export__body--hidden": downloadType !== "link"
      })}>
        <CustomSelect
          zIndex="unset"
          label={linkDuration}
          value={selectedExpireTime}
          defaultValue={exportExtensions[0]}
          changeFunc={setSelectedExpireTime}
          options={expireTimeOptions}
        />
        <Input
          value={exportLink}
          label={link}
          placeholder={generateLink}
          disabled
          rightIcon={
            <>
              <div className="export__icons-container" onClick={exportHandler}>
                <IconSvg tag="orderUpdate" className="export__refresh-icon" />
              </div>
              {exportLink && (
                <div onClick={() => copyText(exportLink, notificationsMain, lang)}>
                  <IconSvg tag="copy" className="export__copy-icon" />
                </div>
              )}
            </>
          }
        />
      </div>
    </CustomModal>
  );
};
