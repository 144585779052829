import { FormProvider, useForm } from "react-hook-form";

import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { FormCheckBox } from "../../../../../components/ui/CheckBoxes/FormCheckBox";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { ApiService } from "../../../../../services/ApiService";
import { errorToast, successToast } from "../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../components/ui/ButtonLoader/ButtonLoader";

export const EmailSettings = ({ controller }) => {
  const { lang } = useParams();
  // Dispatch

  const methods = useForm();
  const { setValue } = methods;
  // **State
  const {
    cabinet: { distribution },
    notifications
  } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [distributionInfo, setDistributionInfo] = useState(null);

  const getDistributionInfo = () => {
    ApiService.getDistributionInfo(controller, lang).then((req) => {
      if (req.status === 200 && req?.data) {
        setDistributionInfo(req.data);
      }
    });
  };

  const getEnabledNotifications = (data) => {
    const filteredNotifications = [];

    for (const notification in data) {
      if (data[notification]) {
        filteredNotifications.push(notification);
      }
    }

    return filteredNotifications;
  };

  const isDataTheSame = (data) => {
    const initialNotifications = distributionInfo.reduce((codes, item) => {
      codes[item.code] = item.enabled;
      return codes;
    }, {});

    return JSON.stringify(initialNotifications) === JSON.stringify(data);
  };

  // Form submit
  const onSubmit = async (data) => {
    if (isDataTheSame(data)) {
      return;
    }

    setIsLoading(true);
    try {
      const enabledNotifications = getEnabledNotifications(data);

      const { status, data: updatedData } = await ApiService.setDistributionInfo(enabledNotifications);

      if (status !== 200) {
        throw status;
      }

      setDistributionInfo(updatedData);
      successToast(notifications?.distributionChange, lang);
    } catch (status) {
      errorToast(notifications?.error, lang);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDistributionInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!distributionInfo) return;

    const initValues = distributionInfo.reduce((acc, item) => {
      acc[item.code] = item.enabled;
      return acc;
    }, {});

    methods.reset(initValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionInfo]);

  const handleCheckBoxChange = (name, value) => {
    setValue(name, value);
  };

  return (
    distributionInfo && (
      <div className="notifications__box">
        <div className="notifications__title">{distribution?.formTitle}</div>
        <div className="notifications__descriptions ">
          <IconSvg tag={"info"} className="info-icon" />
          <div className="info-box">{distribution?.descriptions}</div>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="notifications__form"
          >
            <ul className="notifications__list">
              { distributionInfo.map((item) => (
                <li className="notifications__list-item" key={item.code}>
                  <FormCheckBox
                    name={item.code}
                    value={item.enabled}
                    defaultValue={item.enabled}
                    label={item.categoryName[lang]}
                    handleClick={(e) => handleCheckBoxChange(item.code, e.target.checked)}
                  />
                </li>
              ))}
            </ul>
            <Button type="submit" tab="confirm" initClass="notifications__button">
              {isLoading ? <ButtonLoader /> : distribution?.formBtn}
            </Button>
          </form>
        </FormProvider>
      </div>
    )
  );
};
